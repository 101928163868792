import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: 'https://localhost:44327/',
    userIdentifier: null,
    selectedLocation: 
      {
        untName: null,
        untId: null
      },

    loginUserRequest: null,
    currentScreen: 'home',
    saveCompleteListRequest: null
  },
  mutations: {
    setApiUrl: (state, payload) => {state.baseUrl = payload},
    clearUserInfo: (state) => {state.userIdentifier = null},
    changeLocation: (state, payload) => {state.selectedLocation = payload},
    changeScreen: (state, payload) => {state.currentScreen = payload},
    

    setGetLoginUserRequest: (state, payload) => { state.loginUserRequest = payload },
    setSaveCompleteListRequest: (state, payload) => { state.saveCompleteListRequest = payload },
  },
  actions: {
    
    loginUser({state}){
      
      let payload = state.loginUserRequest
      return new Promise((resolve, reject) =>{
        axios.post(state.baseUrl + 'Token/login', payload)
        .then(response => {
          state.userIdentifier = response.data
          resolve()
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    
    getList({state}){
      let urlDestination = state.baseUrl + 'UserUnit/getList'
      let callConfig = {
        headers: {
          'Authorization': 'Bearer ' + state.userIdentifier
        }
      }

      return new Promise((resolve, reject) =>{
        axios.get(urlDestination, callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    getCompleteUnitList({state}){
      let urlDestination = state.baseUrl + 'Input/getCompleteList/' + localStorage.getItem('selectedDevice')
      let callConfig = {
        headers: {
          'Authorization': 'Bearer ' + state.userIdentifier
        }
      }

      return new Promise((resolve, reject) =>{
        axios.get(urlDestination, callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    getUnitList({state}){
      let urlDestination = state.baseUrl + 'Input/getlist/' + localStorage.getItem('selectedDevice')
      let callConfig = {
        headers: {
          'Authorization': 'Bearer ' + state.userIdentifier
        }
      }

      return new Promise((resolve, reject) =>{
        axios.get(urlDestination, callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })
    },

    saveCompleteList({state}){
      let payload = state.saveCompleteListRequest
      return new Promise((resolve, reject) =>{
        var callConfig = {
        method: 'post',
        url: state.baseUrl + 'Input/savelist',
        headers: {
          'Authorization': 'Bearer '+ state.userIdentifier,
          'Content-Type': 'application/json'
        },
        data : payload
      };
        axios(callConfig)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
      })

    }
    
  },
  modules: {
  }
})
