import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'

import './style.scss'


Vue.config.productionTip = false

Vue.use(BootstrapVue)

Vue.filter('shortDateTimeFilter', function (value) {
  if (value === 'No Data Found') {
    return value
  }else{
    
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
    if (!value) return ''
    value += '+02:00'
    let localeValue = new Date(value)
    let response = localeValue.getDay() + ' ' + months[localeValue.getMonth()] + ' ' + localeValue.getFullYear() + ' ' + localeValue.getHours().toString().padStart(2, '0') + ':' + localeValue.getMinutes().toString().padStart(2, '0')
    return response
  }
})

export const bus = new Vue();

fetch('/config.json')
  .then(res => res.json())  
  .then(config => {
    new Vue({
      data: () => ({
        urlConfig : config.api_url
      }),
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })