<template>
    <div>
        <b-row class="full-height" align-v="center" align-h="center">
            <b-col cols="12" sm="6" lg="4" xl="3" class="p-0">
                <b-card no-body>
                    <b-card-header>
                        <img src="@/assets/background.png" alt="" class="card-img-top">
                        <b-row class="centered mt-0" align-v="center" align-h="center">
                        <div class="text-center">Electrosys - Remote Montoring</div>
                        </b-row>
                    </b-card-header>
                    
                    <b-card-body class="pt-1">
                        <b-alert :show="errors.length > 0" v-for="(item, index) in errors" :key ="index" variant="danger" dismissible>{{item.message}}</b-alert>
                        
                        
                        <label>Username</label>
                        <b-form-input v-model="username" placeholder="Username"></b-form-input>

                        <label> Password</label>
                        <b-form-input v-model="password" placeholder="Password" type="password"></b-form-input>

                        <b-button class="mt-3" @click="parametersCheck()" block variant="primary">
                            <b-spinner small v-if="isLoading"></b-spinner>
                            Login
                        </b-button>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import md5 from 'js-md5'

export default {
    data: () => ({
        // username: 'WilcoS',
        // password: 'WilcoS',
        username: null,
        password: null,
        isLoading: false,
        errors: []
    }),
    mounted() {
        this.$store.commit('setApiUrl', this.$root.urlConfig)
        localStorage.setItem('apiUrl', this.$root.urlConfig)
    },
    methods: {
        ...mapActions(['loginUser']), 
        loginTheUser(){
            this.errors = []
            this.isLoading = true
            var bytes = []; // char codes

            for (var i = 0; i < this.password.length; ++i) {
                var code = this.password.charCodeAt(i);
                bytes = bytes.concat([code]);
            }

            let passwordhash = md5.base64(bytes)

            let request = {
                "email": this.username,
                "password": passwordhash
            }

            this.$store.commit('setGetLoginUserRequest', request)
            this.errors = []
            
            this.loginUser()
            .then(() => {
                localStorage.setItem('username', this.username)
                this.isLoading = false
                if (localStorage.getItem('selectedDevice') === null) {
                    this.$store.commit('changeScreen', 'devices')
                    this.$router.push({path: '/devices'})
                } else {
                    this.$store.commit('changeScreen', 'home')
                    this.$router.push({path: '/home'})
                }
            })
            .catch(() => {
                this.isLoading = false
                this.errors.push({message: 'Invalid credentials. Please try again'})
                this.password = null
            })
        },
        parametersCheck(){
            this.errors = []
            if (this.password !== null) {
                if (this.password.length > 0) {
                    if (this.username !== null) {
                        if (this.username.length > 0) {
                            this.loginTheUser()
                        }
                        else{
                            this.errors.push({message: 'Username needs to be entered'})
                        }
                    }
                    else{
                        this.errors.push({message: 'Username needs to be entered'})
                    }                       
                        }
                else{
                    this.errors.push({message: 'Password needs to be entered'})
                }
            }
            else{
                this.errors.push({message: 'Password needs to be entered'})
            }
            
        }
    }
}
</script>