import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    component: () => import('../OuterView.vue'),
    children:[
      {path: '/home', name:'home', component: () => import('../views/Home.vue')},
      {path: '/devices', name:'devices', component: () => import('../views/ManageDevice.vue')},
      {path: '/deviceManager', name:'deviceManager', component: () => import('../views/Device.vue')},
      {path: '/help', name:'help', component: () => import('../views/About.vue')}
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name != 'Login' && !store.state.userIdentifier) {
    next('')
  } else {
    next()
  }
})

export default router
